import { createRouter, createWebHashHistory } from "vue-router";
import AppContainer from "@/AppContainer.vue";
import FormView from "@/views/FormView";
import HomeView from "@/views/HomeView.vue";
import DatosMedicacion from "@/components/DatosMedicacion.vue";
import GuardadoSatisfactorioView from "@/views/GuardadoSatisfactorioView.vue";
import GuardadoSatisfactorioAdvertenciaView from "@/views/GuardadoSatisfactorioAdvertenciaView.vue";

const routes = [
  {
    path: "/",
    component: AppContainer,
    children: [
      {
        path: "",
        component: HomeView,
        name: "home",
      },
    ],
  },
  {
    path: "/form",
    component: AppContainer,
    children: [
      {
        path: "",
        component: FormView,
        name: "Form",
      },
    ],
  },
  {
    path: "/medform",
    component: AppContainer,
    children: [
      {
        path: "",
        component: DatosMedicacion,
        name: "MedForm",
      },
    ],
  },
  {
    path: "/success",
    component: AppContainer,
    children: [
      {
        path: "",
        component: GuardadoSatisfactorioView,
        name: "success",
      },
    ],
  },
  {
    path: "/success_warning",
    component: AppContainer,
    children: [
      {
        path: "",
        component: GuardadoSatisfactorioAdvertenciaView,
        name: "success_warning",
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
