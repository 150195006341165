<template>
    <v-card class="mx-auto d-flex align-center" variant="text" max-width="344" style="height: 80vh;">
        <v-card-text>
            <v-select :items="items" label="Language / Idioma" v-model="selectedLang"></v-select>
            <v-btn @click="next()">Next / Siguiente</v-btn>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name: 'App',
    data() {
        return {
            selectedLang: null,
            items: [
                { title: 'English', lang: 'en' },
                { title: 'Español', lang: 'es' }
            ],
        };
    },
    methods: {
        next() {
            // Obtenemos el objeto de idioma seleccionado
            const selectedLanguage = this.items.find(item => item.title === this.selectedLang);

            // Validamos si se encontró el idioma seleccionado
            if (selectedLanguage) {
                // Configuramos el idioma en el objeto $i18n
                this.$i18n.locale = selectedLanguage.lang;
                localStorage.setItem('lang', selectedLanguage.lang);
                // Redirigimos al usuario a la página de formulario ('/form')
                this.$router.push('/form');
            } else {
                // Manejar caso donde no se selecciona un idioma válido (puedes mostrar un mensaje de error)
                console.error('Please select a valid language / Por favor seleccione un idioma válido.');
            }
        }
    }
};
</script>
