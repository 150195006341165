<template>
    <v-card variant="text" class="mt-4 mx-auto">
        <v-card-text>
            <v-form v-model="form" @submit.prevent="next">
                <p class="text-center text-h5 mb-4">{{ $t('app.Labels.MedInformation').toUpperCase() }}</p>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-select v-model="selectedUnits" :items="unitOptions" item-value="value" item-title="text"
                            :label="$t('app.Fields.SelectUnits')"></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="5">
                        <v-text-field class="my-4" type="number" v-model="signos.peso"
                            :suffix="selectedUnits === 'metric' ? 'kgs' : 'lbs'" :label="$t('app.Fields.Weight')"
                            persistent-hint :hint="$t('app.Hints.Weight')"
                            :rules="[v => !!v || $t('app.Errors.WeightValidation')]"></v-text-field>
                        <v-text-field class="my-4" type="number" v-model="signos.talla"
                            :suffix="selectedUnits === 'metric' ? 'm' : 'ft'" :label="$t('app.Fields.Tall')"
                            persistent-hint :hint="$t('app.Hints.Tall')"
                            :rules="[v => !!v || $t('app.Errors.TallValidation')]"></v-text-field>
                    </v-col>
                    <v-col cols="7" class="text-left">
                        <v-card class="d-flex flex-column mx-auto my-5">
                            <div class="d-flex justify-center text-h5" :class="imcClass">
                                {{ $t('app.Labels.BMI') }}
                            </div>
                            <div class="d-flex align-center flex-column">
                                <div class="text-h5" :class="imcClass">
                                    {{ imc }}
                                </div>
                            </div>
                            <div class="d-flex align-center flex-column my-2">
                                <div class="text-caption ma-3" :class="imcClass">
                                    {{ imcDescription }}
                                    <p> {{ adjunto1 }}</p>
                                    <p> {{ adjunto2 }}</p>
                                    <v-row v-if="imcValue >= 35 && imcValue <= 40" class="my-2">
                                        <v-col cols="12">
                                            <div class="text-grey text-caption">{{ $t('app.Fields.IMCFiles1') }}</div>
                                            <v-file-input :label="$t('app.Labels.Attach')"
                                                v-model="InformeMedicoObesidad"
                                                :rules="[v => !!v.length || $t('app.Errors.AtttachDocumentValidation')]"
                                                multiple></v-file-input>
                                        </v-col>
                                        <v-col cols="12" class="mt-2">
                                            <div class="text-grey text-caption">{{ $t('app.Fields.IMCFiles2') }}</div>
                                            <v-file-input :label="$t('app.Labels.Attach')"
                                                v-model="ReporteLaboratorioObesidad"
                                                :rules="[v => !!v.length || $t('app.Errors.AtttachDocumentValidation')]"
                                                multiple></v-file-input>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-checkbox v-model="declareNoCoca" :label="$t('app.Labels.NoCoca')" density="compact"
                            :rules="[v => !!v || $t('app.Errors.DeclarationRequired')]"></v-checkbox>
                    </v-col>
                    <v-col cols="12">
                        <v-checkbox v-model="declareNoMarijuana" :label="$t('app.Labels.NoMarijuana')" density="compact"
                            :rules="[v => !!v || $t('app.Errors.DeclarationRequired')]"></v-checkbox>
                    </v-col>
                </v-row>
                <v-alert v-if="imcOutOfRange" type="warning" dense>{{ $t('app.Errors.BMIOutOfRange') }}</v-alert>
                <v-card-actions>
                    <v-btn class="mt-2" color="orange-darken-1" variant="flat" @click="atras()"
                        :text="$t('app.Buttons.Back')"></v-btn>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="!formIsValid" class="mt-2" color="success" variant="flat" type="submit"
                        :text="$t('app.Buttons.Next')"></v-btn>
                </v-card-actions>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'App',
    data: () => ({
        form: false,
        unitOptions: [
            { value: 'metric', text: 'kg/m' },
            { value: 'imperial', text: 'lbs/ft' }
        ],
        informeMedico: [],
        reporteLaboratorio: []
    }),
    created() {
        this.setUnitsByLanguage(this.$i18n.locale);
    },
    watch: {
        '$i18n.locale'(newLocale) {
            this.setUnitsByLanguage(newLocale);
        }
    },
    methods: {
        next() {
            if (!this.formIsValid) return;
            this.$store.commit('SET_VENTANA', 4);
        },
        atras() {
            this.$store.commit('SET_VENTANA', 2);
        },
        setUnitsByLanguage(locale) {
            if (locale === 'en') {
                this.selectedUnits = 'imperial';
                this.convertUnitsToImperial();
            } else {
                this.selectedUnits = 'metric';
                this.convertUnitsToMetric();
            }
        },
        convertUnitsToImperial() {
            // Convert kg to lbs and m to ft only if they are in metric
            if (this.signos.peso && !this.isImperial(this.signos.peso, 'peso')) {
                this.signos.peso = (this.signos.peso * 2.20462).toFixed(2);
            }
            if (this.signos.talla && !this.isImperial(this.signos.talla, 'talla')) {
                this.signos.talla = (this.signos.talla * 3.28084).toFixed(2);
            }
        },
        convertUnitsToMetric() {
            // Convert lbs to kg and ft to m only if they are in imperial
            if (this.signos.peso && this.isImperial(this.signos.peso, 'peso')) {
                this.signos.peso = (this.signos.peso / 2.20462).toFixed(2);
            }
            if (this.signos.talla && this.isImperial(this.signos.talla, 'talla')) {
                this.signos.talla = (this.signos.talla / 3.28084).toFixed(2);
            }
        },
        isImperial(value, type) {
            if (type === 'peso') {
                // Arbitrary threshold to determine if the weight is in imperial units
                return value > 100; // Adjusted threshold for weight in lbs
            } else if (type === 'talla') {
                // Arbitrary threshold to determine if the height is in imperial units
                return value > 3; // Adjusted threshold for height in ft
            }
            return false;
        }
    },
    computed: {
        signos: {
            get() {
                return this.$store.state.signos ? this.$store.state.signos : { peso: 0, talla: 0 };
            },
            set(newValue) {
                this.$store.commit('SET_SIGNOS', newValue);
            }
        },
        selectedUnits: {
            get() {
                return this.$store.state.selectedUnits;
            },
            set(newValue) {
                this.$store.commit('SET_UNITS', newValue);
            }
        },
        imc() {
            const peso = this.selectedUnits === 'metric' ? this.signos.peso : this.signos.peso / 2.20462;
            const talla = this.selectedUnits === 'metric' ? this.signos.talla : this.signos.talla / 3.28084;
            if (peso && talla) {
                return (peso / (talla * talla)).toFixed(2);
            }
            return 0;
        },
        imcClass() {
            const imcValue = parseFloat(this.imc);
            if (imcValue >= 40) {
                return 'obesity-grade-3';
            } else if (imcValue >= 35) {
                return 'obesity-grade-2';
            } else if (imcValue >= 30) {
                return 'obesity-grade-1';
            } else {
                return 'normal';
            }
        },
        imcDescription() {
            const imcValue = parseFloat(this.imc);
            if (imcValue >= 40) {
                return this.$t('app.Labels.BMIMessage3');
            } else if (imcValue >= 35) {
                return this.$t('app.Labels.BMIMessage2');
            } else if (imcValue >= 30) {
                return this.$t('app.Labels.BMIMessage1');
            } else {
                return this.$t('app.Labels.BMIMessage1');
            }
        },
        adjunto1() {
            const imcValue = parseFloat(this.imc);
            if (imcValue >= 35 && imcValue <= 40) {
                return this.$t('app.Labels.BMIMessage21');
            } else {
                return '';
            }
        },
        adjunto2() {
            const imcValue = parseFloat(this.imc);
            if (imcValue >= 35 && imcValue <= 40) {
                return this.$t('app.Labels.BMIMessage22');
            } else {
                return '';
            }
        },
        formIsValid() {
            return this.form && this.declareNoCoca && this.declareNoMarijuana && this.isIMCValid;
        },
        imcValue() {
            return parseFloat(this.imc);
        },
        isIMCValid() {
            const imcValue = this.imcValue;
            return imcValue >= 18 && imcValue <= 60;
        },
        imcOutOfRange() {
            return !this.isIMCValid;
        },
        InformeMedicoObesidad: {
            get() {
                return this.$store.state.documentos_adjuntos.InformeMedicoObesidad;
            },
            set(newValue) {
                this.$store.commit('SET_INFORME_MEDICO_OBESIDAD', newValue);
            }
        },
        ReporteLaboratorioObesidad: {
            get() {
                return this.$store.state.documentos_adjuntos.ReporteLaboratorioObesidad;
            },
            set(newValue) {
                this.$store.commit('SET_REPORTE_LABORATORIO_OBESIDAD', newValue);
            }
        },
        declareNoCoca: {
            get() {
                return this.$store.state.declareNoCoca;
            },
            set(newValue) {
                this.$store.commit('SET_DECLARE_NO_COCA', newValue);
            }
        },
        declareNoMarijuana: {
            get() {
                return this.$store.state.declareNoMarijuana;
            },
            set(newValue) {
                this.$store.commit('SET_DECLARE_NO_MARIJUANA', newValue);
            }
        }
    }
}
</script>

<style>
.obesity-grade-1 {
    color: #ffa500;
    /* Naranja */
}

.obesity-grade-2 {
    color: #ff4500;
    /* Rojo oscuro */
}

.obesity-grade-3 {
    color: #ff0000;
    /* Rojo */
}

.normal {
    color: #008000;
    /* Verde */
}
</style>
