<template>
    <v-col cols="12" offset-lg="4" offset-md="1" lg="4" md="10" sm="12" xs="12">
        <Presentacion v-if="ventana == 1" />
        <DatosPersonales v-if="ventana == 2" />
        <DatosMedicacion v-if="ventana == 3" />
        <DatosMedicos v-if="ventana == 4" />
        <!--         <AdjuntarArchivos v-if="ventana == 4" /> -->
        <AutorizacionDatos v-if="ventana == 5" />
    </v-col>
</template>
<script>
import DatosPersonales from '@/components/DatosPersonales'
import DatosMedicos from '@/components/DatosMedicos.vue';
import DatosMedicacion from '@/components/DatosMedicacion.vue';
/* import AdjuntarArchivos from '@/components/AdjuntarArchivos.vue' */
import AutorizacionDatos from '@/components/AutorizacionDatos.vue'
import Presentacion from '@/components/Presentacion.vue'
export default {

    name: 'App',
    components: {
        DatosPersonales,
        DatosMedicos,
        DatosMedicacion,
        /*         AdjuntarArchivos, */
        AutorizacionDatos,
        Presentacion
    },
    data: () => ({
    }),
    methods: {
    },
    computed: {
        // Definición de una propiedad computada llamada 'area'
        ventana() {
            return this.$store.state.ventana
        },
    },
    created() {
        this.$store.dispatch('fetchEmpresas')
    }

}
</script>