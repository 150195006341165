<template>
    <v-sheet class="pa-4 my-4 text-center mx-auto" elevation="12" max-width="600" rounded="lg" width="100%">
        <v-icon class="mb-5" color="success" icon="mdi-check-circle" size="112"></v-icon>

        <h2 class="text-h5 mb-6">{{ $t('app.Labels.DataSuccess') }}</h2>

        <p class="mb-4 text-medium-emphasis text-body-2">
            {{ $t('app.Labels.MessageDataSuccess') }}
        </p>
        <v-divider class="mb-4"></v-divider>
        <div class="text-end">
            <v-btn class="text-none" @click="home()" color="success" variant="flat" width="90" rounded>
                {{ $t('app.Buttons.Done') }}
            </v-btn>
        </div>
    </v-sheet>
</template>
<script>
export default {
    name: 'App',
    data() {
        return {
        };
    },
    methods: {
        home() {
            this.$store.commit('SET_VENTANA', 1)
            this.$router.push('/form');
        }
    }
};
</script>