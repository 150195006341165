<template>
  <v-app id="inspire">
    <v-app-bar color="indigo-darken-4">
      <v-app-bar-nav-icon icon="mdi-pen"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ $t('app.AppBar').toUpperCase() }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip :text="$t('app.ToolTip.Theme')" location="bottom">
        <template v-slot:activator="{ props }">
          <v-icon class="mx-2" @click="toggleTheme" v-bind="props" icon="mdi-theme-light-dark"></v-icon>
        </template>
      </v-tooltip>
      <v-menu transition="slide-y-transition">
        <template v-slot:activator="{ props }">
          <v-btn class="mx-2" v-bind="props" variant="flat" color="orange-darken-1">{{ $t('app.LanguajeButton')
            }}</v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(item, i) in items" :key="i" @click="changeLanguage(item.lang)">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
    <v-footer app color="indigo-darken-4">
      &#64;INTERNATIONAL SOS {{ new Date().getFullYear() }}
    </v-footer>
  </v-app>
</template>
<script setup>
import { useTheme } from 'vuetify'

const theme = useTheme()

// Obtener el tema preferido del almacenamiento local al cargar la página
theme.global.name.value = localStorage.getItem('theme') || theme.global.name.value

function toggleTheme() {
  // Cambiar el tema
  theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark'

  // Guardar la preferencia del tema en el almacenamiento local
  localStorage.setItem('theme', theme.global.name.value)
}
</script>
<script>
export default {
  name: 'App',
  data: () => ({
    items: [
      { title: 'Español', lang: "es" },
      { title: 'English', lang: "en" },

    ],
  }),
  mounted() {
  },
  methods: {
    changeLanguage(locale) {
      // Cambiar el idioma de la aplicación
      this.$i18n.locale = locale;
      localStorage.setItem('lang', locale);
    }
  }
}
</script>