// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

// Obtén el tema del sistema
const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
const isDarkMode = darkModeMediaQuery.matches

export default createVuetify(
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
  {
    theme: {
      defaultTheme: isDarkMode ? 'dark' : 'light', // Establece el tema predeterminado según el sistema
    },
    icons: {
      defaultSet: 'mdi',
    }
  }
)
